<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-center">
                <h1>Noodzly admin panel</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="8">
                <v-card
                    class="StatsCard"
                >
                    <v-card-title>Stats page</v-card-title>
                    <v-card-text>
                        <v-row class="font-weight-bold">
                            <v-col>Total number of users:</v-col>
                            <v-col>{{ stats.number_users }}</v-col>
                        </v-row>
                        <v-row class="font-weight-bold">
                            <v-col>Total number of online users:</v-col>
                            <v-col>{{ stats.number_online_users }}</v-col>
                        </v-row>
                        <v-row class="font-weight-bold">
                            <v-col>Total number of Noodzlers:</v-col>
                            <v-col>{{ stats.number_noodzlers }}</v-col>
                        </v-row>
                        <v-row class="font-weight-bold">

                            <v-col hint="it resets the first of the month">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                            v-bind="attrs"
                                            v-on="on"
                                        >Number of monthly active users:</span>

                                    </template>
                                    <span>it resets the first of the month</span>
                                </v-tooltip>
                            </v-col>
                            <v-col>{{ stats.number_active_users }}</v-col>
                        </v-row>
                        <v-row class="font-weight-bold">
                            <v-col>Number of new users by day:</v-col>
                            <v-col>{{ stats.number_users_day }}</v-col>
                        </v-row>
                        <v-row class="font-weight-bold">
                            <v-col>Number of new users by week:</v-col>
                            <v-col>{{ stats.number_users_month }}</v-col>
                        </v-row>
                        <v-row class="font-weight-bold">
                            <v-col>Number of new users by month:</v-col>
                            <v-col>{{ stats.number_users_week }}</v-col>
                        </v-row>
                        <v-row class="font-weight-bold">
                            <v-col>Statistics calculated:</v-col>
                            <v-col class="d-flex align-center">{{ moment(stats.created_at).format('LLL') }}
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ml-3"
                                            hint="refresh stats"
                                            @click="handleRecalculate"
                                            small
                                        >
                                            <v-icon small>mdi-refresh</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Recalculate Statistics</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </admin-dashboard-layout>
</template>

<script>

import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import Statistics from "@/models/admin/Statistics";
import RecalculateStatistics from "../../models/admin/RecalculateStatistics";

export default {
    name: "dashboard-index",
    components: {AdminDashboardLayout},
    data: function () {
        return {
            stats: {
                type: Object,
                default() {
                    return {
                        number_active_users: 0,
                        number_noodzlers: 0,
                        number_users: 0,
                        number_online_users: 0,
                        number_users_day: 0,
                        number_users_month: 0,
                        number_users_week: 0,
                        created_at: 0,
                    }
                }
            }
        }
    },
    methods: {
        handleRecalculate() {
            RecalculateStatistics.get().then((response) => {
                this.stats = response.data.pop()
            })
        },
    },
    async mounted() {
        Statistics.get().then((response) => {
            this.stats = response.data.pop()
        })
    }
}
</script>

<style lang="scss" scoped>
.StatsCard {
    border: 1px solid #FB9F1E
}
</style>
